import React from 'react';
import { Layout, SEO } from 'components/common';
import { Intro, Skills, Contact } from 'components/landing';
import {Projects} from 'components/landing/Projects';

export default () => (
  <Layout>
    <SEO />
    <Intro />
  </Layout>
);
